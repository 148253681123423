<template>
  <v-container fluid class="ma-0 pa-0 align-self-start">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card width="100%">
          <v-card-text class="pa-0">
            <v-data-table
                multi-sort
                :headers="headers"
                :items="availableReports"
                :search="search"
                :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Suche"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.link="{ item }">
                <v-btn class="mx-auto" icon small :href="item.link" download>
                  <v-icon small>mdi-download-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.status="{ item }">
                <v-icon class="mx-auto" :color="item.status" small>mdi-circle</v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    @click="confirm(item.id)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="confirmation"
        top
        dark
        vertical
        color="#232323"
    >
      <v-card tile flat color="transparent">
        <v-card-text class="pa-4">
          <p>Möchten Sie diesen Bericht wirklich löschen?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="#fff"
              text
              @click="confirmation = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              @click="deleteItem"
              type="button"
              color="#fff"
              text
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'Reports',
  data () {
    return {
      confirmation: false,
      id: null,
      search: '',
      reports: [],
      loading: false,
      headers: [
        { text: 'der Name', value: 'name', sortable: true },
        { text: 'die E-Mail-Adresse', value: 'email', sortable: true },
        { text: 'die Organisation', value: 'organisation', sortable: true },
        { text: 'Link zum Bericht', value: 'link', sortable: true, align: 'center' },
        { text: 'Ergebnis', value: 'status', sortable: true, align: 'center' },
        { text: 'Datum', value: 'created_at', sortable: true },
        { text: 'Aktionen', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  beforeMount() {
    this.getReports()
  },
  mounted () {
    this.loading = 'primary'
  },
  methods: {
    async getReports () {
      await this.$http.get('/auth/reports')
          .then(response => {
            this.reports = response.data.reports
          })
          .finally(() => {
            this.loading = false
          })
    },
    confirm(id) {
      this.id = id
      this.confirmation = true
    },
    deleteItem() {
      this.$http.delete(`/auth/reports/${this.id}`)
          .then((response) => {
            this.reports = response.data.reports
            this.confirmation = false
            this.id = null
          })
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_USER
    },
    availableReports () {
      return this.reports
    }
  }
}
</script>

<style scoped>

</style>
